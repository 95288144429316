.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fb9a63;
}

.swiper-button-prev:after {
  color: #fb9a63 !important;
}

.swiper-button-next:after {
  color: #fb9a63 !important;
}

.aboutus-Swiper_Button {
  background: var(--iq-pink);
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.swiper_div {
  display: flex;
  margin-top: 20px;
}

.swiper_slider_div {
  padding: 0px 0px 30px;
}

.swiper_slider_Component_div {
  padding-bottom: 20px;
}

.drawpage_image {
  width: auto;
  height: auto;
  object-fit: contain;
}

.swiper_div_text {
  color: #fb9a63;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--iq-black) !important;
  width: 8px;
  height: 8px;
}

.swiper-pagination-bullet {
  background-color: var(--iq-pink) !important;
  opacity: 1 !important;
}

.aboutus_swiper_slider_div {
  background: transparent;
  border: none;
  padding: 20px 0px;
}

.homepage_swiper_div {
  margin: 20px 0px;
}

.homepage_swiperslider_div {
  border: none;
  padding: 0px 0px 20px 0px;
  margin-bottom: 20px;
}

.homepage_image {
  height: 99%;
  width: 99%;
  border-radius: 15px;
}

.custom_HomeSwiper
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--iq-black) !important;
  width: 11px;
  height: 11px;
}

.custom_HomeSwiper .swiper-pagination-bullet {
  background-color: var(--iq-pink) !important;
  opacity: 1 !important;
  width: 11px;
  height: 11px;
}

.custom_HomeSwiper
  .swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0px 10px;
}

.video-container {
  padding: 0px 20px;
  width: 90%;
}

.video-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.swiper .swiper-button-prev:after,
.swiper .swiper-button-next:after {
  font-size: 20px;
}

@media (max-width: 575px) {
  .custom_HomeSwiper .swiper-pagination-bullet,
  .custom_HomeSwiper
    span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    height: 8px;
    width: 8px;
    margin: 0px 7px;
  }
  .homepage_image {
    border: none;
    border-radius: 0;
  }
  .custom_HomeSwiper .swiper-horizontal > .swiper-pagination-bullets,
  .custom_HomeSwiper .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 13px);
  }
}
